<template>
    <v-dialog v-model="shows" width="840" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col class="py-2" cols="4">
                        <v-text-field v-model="form.name" label="이름" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <v-select v-model="form._category" :items="categoryItems" label="카테고리" v-bind="{ ...attrs_input, loading }" multiple clearable/>
                    </v-col>
                    <v-col cols="2">
                        <v-checkbox v-model="all" class="mt-0" label="모두선택" hide-details @change="checkAll"/>
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <v-text-field v-model="form.code" label="코드" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                    <v-col class="py-2" cols="6">
                        <v-text-field v-model="form.desc" label="설명" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

const initForm = (form = {}) => ({
    _category: form?._category || [],

    name: form?.name || null,
    code: form?.code || null,
    desc: form?.desc || null,
});

export default {
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initForm(),
        categories: [],

        shows: false,
        loading: false,
        all: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "필터링";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
        categoryItems() {
            return this.$store.state.categories.flatMap(function flatMap(category) {
                let categories = [category];
                if (0 < category.children.length) {
                    categories.push(...category.children.flatMap(flatMap));
                }
                return categories;
            }).map(({ name, _id }) => ({ text: name, value: _id }));
        }
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
        "form._category"() {
            this.all = this.categoryItems.length === this.form._category.length;
        }
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initForm();
                } else {
                    let { _id } = this;
                    this.form = (await api.console.shop.filterings.get({ _id })).filtering;
                    this.$emit("input", { ...this.form });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { name } = this.form;
            if (!name) throw new Error("이름을 등록하셔야 합니다");
        },
        checkAll(value) {
            if(value) this.form._category = this.categoryItems.map(({ value }) => value);
            else this.form._category = [];
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.filterings;
                let filtering = (await (this.isCreate ? post : put)(this.form))?.filtering;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", filtering);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
        line-height: 1.3;
    }
}
</style>
