<template>
    <v-data-table v-bind="{ items, headers, loading }" disable-filtering disable-pagination disable-sort hide-default-footer :items-per-page="-1" :class="{ 'v-sheet--outlined': mode == 'form' }" ref="data-table">
        <template #top>
            <view-section-title title="옵션정보">
                <template #actions>
                    <v-btn text icon color="primary" @click="push()"> <v-icon>mdi-plus</v-icon> </v-btn>
                </template>
            </view-section-title>
            <v-divider/>
        </template>
        <template #[`item.key`]="{ item }">
            <v-combobox></v-combobox>
        </template>
        <template #[`item.value`]="{ item }">
            <v-text-field></v-text-field>
        </template>
        <template #[`item.actions`]="{ item }">
            <v-btn text icon color="red" @click="pull(item)">
                <v-icon> mdi-minus </v-icon>
            </v-btn>
        </template>
    </v-data-table>        
</template>

<script>
import { attrs_switch, initProduct, PRODUCT_TYPES } from "@/assets/variables";
import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

export const initProductProperty = (property = []) => ({
    key: property?.key || "",
    value: property?.value || null
})
export default {
    components: {
        ViewSectionTitle,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        attrs_switch,
        PRODUCT_TYPES,
    }),
    computed: {
        items() {
            return this.form.properties.map((item, index) => ({ ...item, index: index + 1 }));
        },
        headers() {
            return [
                { value: "key", text: "이름", width: 80, class: this.mode == "form" ? "pa-0" : undefined },
                { value: "value", text: "값", width: 110 },
                { value: "actions", text: "", cellClass: "px-4 vertical-align-middle", width: 30, hides: this.mode != "form" },
            ]
        }
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        push(item = initProductProperty()) {
            this.form.properties.push(item);
            this.emit();
        },

        pull(item) {
            this.form.properties.splice(item.index - 1, 1);
            this.emit();
        },

        sync() {
            this.form = initProduct(this.value);
        },

        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
