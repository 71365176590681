<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%">
            <list-heading title="필터링 목록">
                <template #add-button>
                    <filtering-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </filtering-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.actions`]="{ item }">
                    <filtering-form v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="{ ...attrs, loading }" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </filtering-form>
                    <v-btn small text icon tile v-bind="{ loading }" @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import { attrs_switch, initDataTableHeaders } from "@/assets/variables";
import FilteringForm from "@/components/console/shop/filterings/filteringForm.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = initDataTableHeaders([
    { text: "이름", value: "name" },
    { text: "코드", value: "code" },
    { text: "설명", value: "desc" },
    // { width: +200, text: "카테고리", value: "category", formatter: ({ category }) => value },
    { width: 100, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: +88, text: "", value: "actions", align: "center" },
]);

export default {
    components: {
        FilteringForm,
        ListHeading,
    },
    data: () => ({
        filterings: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        attrs_switch,
        loading: false,
    }),
    computed: {
        items() {
            return [...this.filterings];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.group = this.group;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapActions(['getCategories']),
        async init() {
            try {
                await this.getCategories();
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, filterings } = await api.console.shop.filterings.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.filterings = filterings.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(filtering) {
            try {
                filtering = (await api.console.shop.filterings.put(filtering))?.filtering;
                this.updateItem(filtering);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.search();
            }
        },

        async remove(filtering) {
            const go = confirm(`"${filtering?.name}" 필터링을 삭제하시겠습니까?`);
            if (go) {
                try {
                    filtering = (await api.console.shop.filterings.delete(filtering))?.filtering;
                    this.filterings = this.filterings.filter(({ _id }) => _id !== filtering._id);
                    alert(`"${filtering?.name}" 필터링이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                } finally {
                    this.search();
                }
            }
        },

        updateItem(filtering) {
            const index = this.filterings.findIndex(({ _id }) => _id == filtering._id);
            if (0 <= index) this.filterings.splice(index, 1, filtering);
            else {
                this.filterings = [filtering, ...this.filterings];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
