var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    ref: "data-table",
    class: {
      'v-sheet--outlined': _vm.mode == 'form'
    },
    attrs: {
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('view-section-title', {
          attrs: {
            "title": "옵션정보"
          },
          scopedSlots: _vm._u([{
            key: "actions",
            fn: function () {
              return [_c('v-btn', {
                attrs: {
                  "text": "",
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.push();
                  }
                }
              }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            },
            proxy: true
          }])
        }), _c('v-divider')];
      },
      proxy: true
    }, {
      key: `item.key`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-combobox')];
      }
    }, {
      key: `item.value`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-text-field')];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.pull(item);
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-minus ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }